<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Work orders dashboard > All</h1>
    <hr>
    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-secondary dropdown-toggle"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtnExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            @grid-ready="onGridReady"
            :rowData="rowData">
        </ag-grid-vue>
    </div>

    <div class="row mt-2">
        <div class="col">
            <button @click="routeBack" class="btn btn-outline-secondary float-end">
                <i class="bi bi-arrow-return-left"></i> Go back
            </button>
        </div>
    </div>
</div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import { dateRenderer } from '../../shared/helpers/GridRenderers'
import { seconds2TimeString } from '../../shared/helpers/dateTimeHelpers'
import {defaultButtonColumnDef, useGrid} from '../../shared/composables/useGrid'
import TableButtonInfo from '../../shared/components/TableButtonInfo'
import { dateComparator } from '../../shared/helpers/GridComparators'

import CellGridOrderStatus from '../components/CellGridOrderStatus'
import { getOrdersInfo } from '../helpers/endpoints';


const timeSpendRenderer = (timeSpend) => {
    if (timeSpend.value)
        return seconds2TimeString(timeSpend.value)
    else
        return '-'
}

export default {
    components: { AgGridVue },
    setup() {
        const router = useRouter()

        const { 
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(() => {
            getOrdersInfo().then((response) => {
                console.log(response);
                rowData.value = response;
            })
        })
        const columnDefs = reactive([
                { headerName: "Code", field: "code", filter: 'agTextColumnFilter' },
                { headerName: "Product", field: "product", filter: 'agTextColumnFilter' },
                { headerName: "Quantity", field: "quantity", filter: 'agNumberColumnFilter' },
                { 
                    headerName: "Start date", 
                    field: "start_date",
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer ,
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                { 
                    headerName: "Status", 
                    field: "status",
                    cellRenderer: 'statusRenderer'
                },
                {
                    headerName: 'Registry time (HH:mm:ss)',
                    field: 'time',
                    cellRenderer: timeSpendRenderer
                },
                {
                    headerName: 'time/item (HH:mm:ss)',
                    field: 'time',
                    valueGetter: (params) => {
                        return seconds2TimeString(params.data['time'] / params.data['quantity'])
                     },
                },
                {
                    headerName: 'Notifications',
                    field: 'total_notifications', 
                    filter: 'agNumberColumnFilter'
                },
                {
                    field: 'id',
                    cellRenderer: 'btnInfoRenderer',
                    cellRendererParams: {
                        action: (data) => {
                            console.log(data.id);
                            router.push({
                                name: 'backoffice-dashboard-order-details',
                                params: {
                                    id: data.id
                                },
                            })
                        }
                    },
                    ...defaultButtonColumnDef
                }
            ])

        const frameworkComponents = reactive({
            btnInfoRenderer: TableButtonInfo,
            statusRenderer: CellGridOrderStatus
        })

        return {
            columnDefs,
            defaultColDef,
            frameworkComponents,
            rowData,

            onGridReady,
            onBtnExport: () => exportAsCSV(),

            routeBack: () => router.back()
        }
    }
}
</script>